<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData"/>
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'sanierung-taunusstein',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath: 'https://medo-sanierung.de/public_files/img/references/sanierung-taunusstein',
      imageCount: 24,
      imageFileFormat: '.jpg',
      name: 'Sanierung einer Wohnung in Taunusstein',
      description:
          'Sanierung einer Wohnung in Taunusstein. Die 4-Zimmer-Wohnung in Taunusstein wurde saniert und modernisiert. Nachdem die alten Wand-und Bodenbeläge, sowie alle Türen entfernt wurden, folgten die Verputz-, Trockenbau-, Spachtel-, Maler- und Fliesenarbeiten. Der Eichenparkett wurde geklebt, geschliffen,gebürstet, gebeizt und geölt.Mit dem Einbau der Zimmer- und Schiebetüren war es vollendet.',
    },
    //
  }),
};
</script>
